<template>
	<v-container fill-height fluid class="pa-0 fac-background-img">
		<v-row no-gutters align="center" justify="center">
			<v-col sm="12" md="6" cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'pa-2'">
				<v-alert border="left" colored-border type="error" elevation="2" dismissible :value="error" class="animate__animated animate__flash">
					{{ errorMessage }}
				</v-alert>
				<v-card>
					<v-card-title class="primary--text">
						Faça seu cadastro no FAC!
					</v-card-title>
					<v-row no-gutters>
						<v-col sm="12" md="12" cols="12" class="pa-4">
							<v-form @submit.prevent="doRegister" ref="registerForm">
								<v-row row wrap justify-center>
									<v-col cols="6">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Nome'" :placeholder="'Nome'" v-model="user.name" :prepend-inner-icon="'mdi-account'" :rules="[$rules.required, $rules.alpha]"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Sobrenome'" :placeholder="'Sobrenome'" v-model="user.surname" :prepend-inner-icon="'mdi-account'" :rules="[$rules.required, $rules.alpha]"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Email'" :placeholder="'Email'" @keydown.space.prevent v-model.trim="user.email" :prepend-inner-icon="'mdi-email'" :rules="[$rules.required, $rules.email]"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Telefone'" :placeholder="'Telefone'" v-model="user.phone" v-mask="['(##) ####-####', '(##) #####-####']" :prepend-inner-icon="'mdi-phone'" :rules="[$rules.required, $rules.phone]"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Senha'" :placeholder="'Senha'" :prepend-inner-icon="'mdi-lock'" :append-icon="user.showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="user.showPassword ? 'text' : 'password'" @click:append="user.showPassword = !user.showPassword" v-model="user.password" :rules="[$rules.required]"></v-text-field>
									</v-col>
								</v-row>

								<v-card flat class="transparent mt-3">
									<v-card-actions class="pa-0">
										<v-spacer></v-spacer>
										<v-btn depressed class="px-5" color="success" type="submit" :loading="loading">{{ 'Cadastrar' }}</v-btn>
									</v-card-actions>
								</v-card>
							</v-form>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import "../assets/css/animate.min.css"
import bgImage from '../assets/img/fac-img2.jpg'

export default {
	name: 'Register',
	data() {
		return {
			loading: false,
			error: false,
			errorMessage: 'Campos Inválidos',
			image: bgImage,
			user: {
				name: '',
				surname: '',
				email: '',
				phone: '',
				password: '',
				showPassword: false
			}
		}
	},
	beforeMount() {
		this.$store.dispatch('checkAuth').then(auth => {
			if (auth && auth.content && auth.content.id) {
				this.$router.push('/inicio')
			}
		})
	},
	methods: {
		doRegister() {
			this.error = false

			// TRIM FIRST
			if (this.user.email) {
				this.user.email = this.user.email.trim()
			}

			// VALIDATE
			this.$nextTick(() => {
				if (this.$refs.registerForm.validate()) {
					this.loading = true

					const userData = {
						name: this.user.name,
						surname: this.user.surname,
						email: this.user.email ? this.user.email.trim() : this.user.email,
						phone: this.user.phone ? this.$rules.clearMask(this.user.phone) : this.user.phone,
						password: this.user.password,
					}

					this.$store.dispatch('doRegister', userData).then(() => {
						this.loading = false
						this.$router.push('/inicio')
					}).catch(error => {
						this.loading = false
						if (error && error.status && error.status === 403) {
							this.error = true
							this.errorMessage = 'Esse e-mail já está sendo usado.'
						}
					})
				}
				else {
					this.error = true
				}
			})
		}
	}
}
</script>

<style scoped>
body {
	background-color: grey;
}
.fac-background-img {
	background: linear-gradient(
		to top,
		rgba(80,80,80,.6),
		rgba(20,20,20,.8)
	),url('../assets/img/fac-img2.jpg') center center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
</style>
